import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { alpha, colors, s } from '../style'

const months = {
  '01': 'styczeń',
  '02': 'luty',
  '03': 'marzec',
  '04': 'kwiecień',
  '05': 'maj',
  '06': 'czerwiec',
  '07': 'lipiec',
  '08': 'sierpień',
  '09': 'wrzesień',
  10: 'październik',
  11: 'listopad',
  12: 'grudzień',
}

const HistoryItem = ({ data, id }) => {
  const { date, title, desc, type, image, logo, link } = data
  const year = date.slice(0, 4)
  const month = months[date.slice(5, 7)]

  return (
    <div css={[sHistoryItem, image && { marginBottom: '0.5rem' }]}>
      <div
        css={[
          sText,
          image && {
            paddingBottom: '5rem',
            [s.md_down]: { paddingBottom: '4rem' },
            [s.sm_down]: { paddingBottom: '4rem' },
            [s.xs]: { paddingBottom: '4rem' },
          },
        ]}
      >
        <span>
          {month} {year}
        </span>
        <h2>{title}</h2>
        <p>{desc}</p>
        <div css={sIcon}>
          {type.name === 'PRODUKT' && <IconProduct />}
          {type.name === 'WYDARZENIE' && <IconEvent />}
          {type.name === 'LOKALIZACJA' && <IconLocation />}
          {type.name === 'ZMIANA' && <IconTransfer />}
        </div>
        {logo && (
          <div css={sLogoContainer}>
            <img src={logo.file.url} alt={title} />
            {link && (
              <a href={link} target="_blank" rel="noreferrer noopener">
                Odwiedź
              </a>
            )}
          </div>
        )}
      </div>
      {image && (
        <div css={sImageContainer}>
          <GatsbyImage
            image={getImage(image.gatsbyImageData)}
            imgStyle={{ borderRadius: '1rem 0 1rem 1rem' }}
            style={{ borderRadius: '1rem 0 1rem 1rem' }}
          />
          <div css={sDimmer} />
          {link && (
            <a href={link} target="_blank" rel="noreferrer noopener">
              Czytaj więcej
            </a>
          )}
        </div>
      )}
    </div>
  )
}

const sLogoContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginTop: '4rem',

  img: {
    maxWidth: '60%',
  },

  a: {
    height: 'max-content',
    transition: 'all 0.15s ease-out',
    fontWeight: 700,
    letterSpacing: '-0.05em',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: colors.white.concat(alpha[60]),
    backgroundColor: colors.white.concat(alpha[8]),
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    '&:hover': {
      transform: 'scale(1.25)',
      color: colors.white,
      backgroundColor: colors.white.concat(alpha[16]),
    },
  },
}

const sDimmer = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
  background: `linear-gradient(to bottom, ${colors.black.concat(
    alpha[0]
  )}, ${colors.black.concat(alpha[60])})`,
  borderRadius: '1rem',
}

const sImageContainer = {
  position: 'relative',
  top: -24,
  left: 0,

  a: {
    ...sLogoContainer.a,
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
    color: colors.text,
    backgroundColor: colors.white,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.brand,
      boxShadow: `0px 8px 24px ${colors.brand}, 0px 0px 32px ${colors.brand}`,
    },
  },
}

const sIcon = {
  position: 'absolute',
  top: 64,
  right: 64,
  svg: {
    width: 32,
    height: 32,
  },
  opacity: 0.16,
  [s.md_down]: {
    top: 48,
    right: 48,
  },
  [s.sm_down]: {
    top: 32,
    right: 32,
  },
}

const sHistoryItem = {
  marginBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 10,
  animation: 'fadeIn 0.5s ease-in-out',
}

// prettier-ignore
const sText = {
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: '4rem',
  borderRadius: '1rem',
  background: `linear-gradient(
    to bottom right, 
    ${colors.white.concat(alpha[12])},
    ${colors.white.concat(alpha[2])}
    )`,

    'h2, span, p': {
      paddingRight: '2rem',
    },


  span: {
    letterSpacing: '0.1em',
    opacity: 0.3,
    fontWeight: 700,
    fontSize: '0.75rem',
    textTransform: 'uppercase'
  },

  p: {
    fontSize:'0.875rem',
    lineHeight: '1.35em',
    opacity: 0.6
  },



  [s.md_down]: {
    padding: '3rem',

  },

  [s.sm_down]: {
    padding: '2rem',
    h2: {
      margin: '0.5rem 0',
      fontSize: '1.25rem'
    }
  },

  [s.xs]: {
    padding: '2rem',
    'h2, span, p': {
      paddingRight: '1rem',
    },

  },
  
}

const IconProduct = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

const IconEvent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 32">
    <path
      id="Icon_material-event"
      data-name="Icon material-event"
      d="M27.056,19.1H19v8h8.056ZM25.444,1.5V4.7H12.556V1.5H9.333V4.7H7.722A3.2,3.2,0,0,0,4.516,7.9L4.5,30.3a3.21,3.21,0,0,0,3.222,3.2H30.278A3.221,3.221,0,0,0,33.5,30.3V7.9a3.221,3.221,0,0,0-3.222-3.2H28.667V1.5Zm4.833,28.8H7.722V12.7H30.278Z"
      transform="translate(-4.5 -1.5)"
      fill="currentColor"
    />
  </svg>
)

const IconLocation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
      clipRule="evenodd"
    />
  </svg>
)

const IconTransfer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
  </svg>
)

export default HistoryItem
