import React, { useState, useRef, useEffect } from 'react'
import { alpha, colors, s } from '../style'

// const getOpacity = (selected, current, strength) => 1 // (strength ** Math.abs(current - selected)).toPrecision(2)

const HistoryNavigation = ({ year, setYear, years }) => {
  const refs = useRef([])
  const containerRef = useRef(0)

  const onChange = (y, i) => {
    setYear(y)
    // refs.current[i]?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center',
    //   inline: 'center',
    // })
  }

  useEffect(() => {
    refs.current[years.length - 1].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }, [])

  useEffect(() => {
    refs.current[
      year === 'IMPORTANT' ? 'IMPORTANT' : years.indexOf(year)
    ]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
    if (typeof window !== 'undefined')
      if (window.pageYOffset > 0)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500)
  }, [year])

  return (
    <div css={sNavigation}>
      <div css={sYearsOuter}>
        <div css={sDimmer} />
        <div css={sYearsInner} ref={containerRef}>
          {years.map((y, i) => (
            <span
              key={y}
              css={[
                sYear,
                // {
                //   opacity: getOpacity(year, y, 0.5),
                //   [s.md_down]: { opacity: getOpacity(year, y, 0.8) },
                // },
                y === year && sYearSelected,
              ]}
              onClick={() => onChange(y, i)}
              ref={(e) => (refs.current[i] = e)}
            >
              {y}
            </span>
          ))}
          <span
            css={[sYear, year === 'IMPORTANT' && sYearSelected]}
            onClick={() => setYear('IMPORTANT')}
            ref={(e) => (refs.current['IMPORTANT'] = e)}
          >
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </span>
        </div>
        <div css={sHiderL}>
          <Arrow
            onClick={() =>
              year === 'IMPORTANT'
                ? onChange(years[years.length - 1], years.length - 1)
                : onChange(
                    years[years.indexOf(year) - 1],
                    years.indexOf(year) - 1
                  )
            }
            css={[
              sArrow,
              year <= years[0] && {
                opacity: 0,
                pointerEvents: 'none',
              },
              (year === years[years.length - 1] || year === 'IMPORTANT') && {
                transform: 'translate(3rem, calc(-50% + 3rem))',
                [s.xs]: { transform: 'translate(0, -50%)' },
              },
            ]}
          />
        </div>
        <div css={sHiderR}>
          <Arrow
            onClick={() =>
              onChange(years[years.indexOf(year) + 1], years.indexOf(year) + 1)
            }
            css={[
              sArrow,
              (year >= years[years.length - 1] || year === 'IMPORTANT') && {
                opacity: 0,
                pointerEvents: 'none',
              },
              year === years[0] && {
                transform: 'translate(3rem, calc(-50% + 3rem))',
                [s.xs]: {
                  transform: 'translate(0, -50%)',
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

const Arrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
      clipRule="evenodd"
    />
  </svg>
)

const sArrow = {
  cursor: 'pointer',
  pointerEvents: 'auto',
  zIndex: 200,
  position: 'absolute',
  top: '50%',
  width: '2rem',
  height: '2rem',
  right: '2rem',
  tranformOrigin: '0 0',
  transform: 'translateY(calc(-50% + 3rem))',
  // color: colors.greyDark,
  transition:
    'all 0.15s ease-out, opacity 0.6s ease-in-out, transform 0.6s ease-in-out',

  '&:hover': {
    color: colors.white,
    filter: 'drop-shadow(0px 0px 8px white)',
  },

  [s.xs]: {
    transform: 'translateY(-50%)',
    left: '1rem',
  },
}

const sHider = {
  zIndex: 102,
  position: 'absolute',
  height: '100%',
  width: '50vw',
  background: `linear-gradient(
    to right,
    ${colors.greyDarker},
    ${colors.greyDarker.concat(alpha[80])},
    ${colors.greyDarker.concat(alpha[60])},
    ${colors.greyDarker.concat(alpha[0])}
  )`,
  [s.xs]: {
    width: '33vw',
  },
}

const sHiderL = {
  ...sHider,
  top: 0,
  left: 0,
}

const sHiderR = {
  ...sHider,
  right: 0,
  top: 0,
  transform: 'scaleX(-1) ',
}

const sDimmer = {
  // border: '1px solid red',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 50,
  width: '100%',
  height: 'calc(100% + 100px)',
  background: `linear-gradient(
    to bottom, 
    ${colors.greyDarker.concat(alpha[0])},
    ${colors.greyDarker.concat(alpha[60])},
    ${colors.greyDarker.concat(alpha[90])},
    ${colors.greyDarker.concat(alpha[100])},
    ${colors.greyDarker.concat(alpha[100])},
    ${colors.greyDarker},
    ${colors.greyDarker}
    )`,
}

const sYear = {
  opacity: 0.6,
  // mixBlendMode: 'overlay',
  // willChange: 'filter, opacity, transform',
  transition: 'all 0.15s ease-out',
  pointerEvents: 'auto',
  cursor: 'pointer',
  lineHeight: '1em',
  fontSize: '1.5rem',
  letterSpacing: '-0.05em',
  fontWeight: 700,
  margin: '0 3rem',
  '&:hover': {
    [s.hover]: {
      opacity: 1,
      transform: 'scale(1.25)',
    },
  },
  [s.xs]: {
    margin: 0,
    padding: '0 1rem',
    fontSize: '1.25rem',
  },
}

const sYearSelected = {
  opacity: 1,
  // fontSize: '4rem',
  filter: 'drop-shadow(0px 0px 8px white)',
  transform: 'scale(2)',
  '&:hover': {
    [s.hover]: {
      transform: 'scale(2)',
    },
  },
  [s.xs]: {
    transform: 'scale(1.5)',
  },
}

const sNavigation = {
  pointerEvents: 'none',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 100,
  // background: 'red',
  height: '100vh',
  width: '100vw',
}

// prettier-ignore
const sYearsOuter = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100vw',
  // border: '1px solid blue',
  zIndex: 1000,
  height: 'max-content'
}

const sYearsInner = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 101,
  pointerEvents: 'auto',
  display: 'flex',
  flexFlow: 'row nowrap',
  overflowX: 'auto',
  scrollSnapType: 'mandatory',
  textAlign: 'center',
  // justifyContent: 'center',
  // alignItems: 'center',
  scrollSnapAlign: 'center',
  width: '100%',
  padding: '4rem calc(50vw - 50px) 6rem',
  [s.xs]: {
    padding: '3rem 40vw',
  },
}

export default HistoryNavigation
