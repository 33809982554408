import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { seo } from '../content/historia'
import { useLangContext } from '../context/lang.context'
import HistoryNavigation from '../content/HistoryNavigation'
import { alpha, colors, s, sCenteredSpaced } from '../style'
import HistoryItem from '../components/HistoryItem'
import Button from '../components/Button'

const years = [2010, 2011, 2012, 2013, 2015, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]

const Historia = () => {
  const { lang } = useLangContext()
  const [year, setYear] = useState('IMPORTANT')
  // const viewRef = useRef(0)

  const query = useStaticQuery(graphql`
    {
      historia: allContentfulHistoria(
        sort: { fields: date, order: DESC }
        filter: { node_locale: { eq: "pl" } }
      ) {
        nodes {
          important
          title
          date
          desc
          link
          image {
            gatsbyImageData(
              quality: 100
              aspectRatio: 1.77
              formats: JPG
              placeholder: BLURRED
            )
          }
          logo {
            file {
              url
            }
          }
          type {
            name
          }
        }
      }
    }
  `)

  const data =
    year === 'IMPORTANT'
      ? query.historia.nodes.filter(({ important }) => important)
      : query.historia.nodes.filter(
          ({ date }) => parseInt(date.slice(0, 4)) === year
        )

  return (
    <Layout>
      <Seo title={seo.title[lang]} desc={seo.desc[lang]} url={seo.url} />

      <section css={sOuter}>
        <HistoryNavigation year={year} setYear={setYear} years={years} />
        {year === 'IMPORTANT' && (
          <h1
            css={[
              sInfo,
              {
                position: 'relative',
                top: -32,
                left: 0,
                letterSpacing: '0em',
                margin: 0,
                padding: 0,
                color: colors.white.concat(alpha[30]),
                textTransform: 'default',
              },
            ]}
          >
            <strong css={{ color: colors.white, letterSpacing: '0em' }}>
              Historia
            </strong>{' '}
            - wyróżnione wydarzenia firmy
          </h1>
        )}
        {data.length === 0 && (
          <p css={sInfo}>
            BRAK WPISÓW Z ROKU <strong>{year}</strong>
          </p>
        )}
        <div css={[sSection, sSectionLG]}>
          <div css={sColumn}>
            {data.map(
              (item, id) =>
                id % 3 === 0 && <HistoryItem data={item} key={item.date} />
            )}
          </div>
          <div css={sColumn}>
            {data.map(
              (item, id) =>
                id % 3 === 1 && <HistoryItem data={item} key={item.date} />
            )}
          </div>
          <div css={sColumn}>
            {data.map(
              (item, id) =>
                id % 3 === 2 && (
                  <HistoryItem data={item} key={item.date + item.title} />
                )
            )}
          </div>
        </div>
        <div css={[sSection, sSectionSM]}>
          <div css={sColumn}>
            {data.map(
              (item, id) =>
                id % 2 === 0 && (
                  <HistoryItem data={item} key={item.date + item.title} />
                )
            )}
          </div>
          <div css={sColumn}>
            {data.map(
              (item, id) =>
                id % 2 === 1 && (
                  <HistoryItem data={item} key={item.date + item.title} />
                )
            )}
          </div>
        </div>
        <div css={[sSection, sSectionXS]}>
          <div css={sColumn}>
            {data.map((item, id) => (
              <HistoryItem data={item} key={item.date + item.title} />
            ))}
          </div>
        </div>
        {year > years[0] && (
          <div css={sCenteredSpaced}>
            <Button
              small
              dimmed
              action={() => setYear(years[years.indexOf(year) - 1])}
              extraCss={{
                width: 'max-content',
                [s.xs]: { width: 'max-content' },
              }}
            >
              Zobacz poprzedni rok
            </Button>
          </div>
        )}
        {year === 'IMPORTANT' && (
          <div css={sCenteredSpaced}>
            <Button
              small
              dimmed
              action={() => setYear(years[years.length - 1])}
              extraCss={{
                width: 'max-content',
                [s.xs]: { width: 'max-content' },
              }}
            >
              Przeglądaj poszczególne lata
            </Button>
          </div>
        )}
      </section>
    </Layout>
  )
}

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  background: `linear-gradient(to bottom, ${colors.text}, ${colors.greyDarker})`,
  color: 'white',
  top: 0,
  left: 0,
  padding: '12rem 0 10rem',
  [s.xs]: {
    padding: '8rem 0',
  },
}

const sColumn = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}

const sSection = {
  width: '100vw',
  maxWidth: 1500,
  margin: 'auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '2rem',

  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.xs]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}

const sInfo = {
  textAlign: 'center',
  width: '100%',
  fontSize: '1rem',

  letterSpacing: '0.1em',
}

const sSectionXS = {
  [s.sm]: { display: 'none' },
}

const sSectionSM = {
  [s.xs]: { display: 'none' },
  [s.lg]: { display: 'none' },
}

const sSectionLG = {
  [s.md_down]: { display: 'none' },
}

export default Historia
