export const seo = {
  url: 'historia',
  title: {
    pl: 'Historia Firmy | Rozwój firmy TSL',
    en: 'Historia Firmy | Rozwój firmy TSL',
  },
  desc: {
    pl: 'Historia Omida Logistics. Dowiedz się więcej o początkach i rozwoju jednej z największych firmy TSL w Polsce.',
    en: 'Historia Omida Logistics. Dowiedz się więcej o początkach i rozwoju jednej z największych firmy TSL w Polsce.',
  },
  keywords: [
    'omida',
    'logistics',
    'historia',
    'rozwój',
    'polska',
    'firma',
    'tsl',
  ],
}
